@import './variables.sass'

.pill-Artificer, .pill-Barbarian, .pill-Bard, .pill-BloodHunter, .pill-Cleric, .pill-Druid, .pill-Fighter, .pill-Monk, .pill-Paladin, .pill-Ranger, .pill-Rogue, .pill-Sorcerer, .pill-Warlock, .pill-Wizard
  color: $bg-color
  border-radius: 8px
  padding: 0.2em 1em

.pill-Cleric
  background-color: lightyellow
.pill-Paladin
  background-color: gold
.pill-Sorcerer
  background-color: #822
.pill-Artificer
  background-color: gray
.pill-Barbarian
  background-color: red
.pill-Bard
  background-color: #ff8012
.pill-BloodHunter
  background-color: darkred
.pill-Druid
  background-color: #755a3d
.pill-Fighter
  background-color: black
.pill-Monk
  background-color: grey
.pill-Paladin
  background-color: gold
.pill-Ranger
  background-color: green
.pill-Rogue
  background-color: darkgreen
.pill-Warlock
  background-color: black
.pill-Wizard
  background-color: lightblue

.small-pill
  padding: 0.2em 0.4em
