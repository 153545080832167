@use './normalize.sass'
@use './theme.sass'
@use './nav.sass'

#root
  display: grid
  grid-template-columns: [side-nav]min-content [body-column]1fr
  //grid-template-rows: [header] min-content [body-row] max-content [footer] min-content
  grid-template-rows: 1fr
  gap: 0.5em
  height: 100dvh
  width: 100%

main
  grid-column: 2 / -1
  grid-row: 1 / -1
  display: block
  width: 100%
  overflow: auto
nav
  grid-column: 1 / 2
  grid-row: 1 / -1

button
  cursor: pointer


@media only screen and (max-width: 768px)
  main
    grid-column: 1 / -1
    padding: 0 10px
  .hide-mobile
    display: none

