@import './variables'

.tabs
  overflow-x: auto
  max-width: 100%
  > input
    display: none

  > input[type="radio"]:nth-of-type(1):checked ~ ul:nth-of-type(1) > li:nth-of-type(1),
    input[type="radio"]:nth-of-type(2):checked ~ ul:nth-of-type(1) > li:nth-of-type(2),
    input[type="radio"]:nth-of-type(3):checked ~ ul:nth-of-type(1) > li:nth-of-type(3),
    input[type="radio"]:nth-of-type(4):checked ~ ul:nth-of-type(1) > li:nth-of-type(4),
    input[type="radio"]:nth-of-type(5):checked ~ ul:nth-of-type(1) > li:nth-of-type(5),
    input[type="radio"]:nth-of-type(6):checked ~ ul:nth-of-type(1) > li:nth-of-type(6),
    input[type="radio"]:nth-of-type(7):checked ~ ul:nth-of-type(1) > li:nth-of-type(7),
    input[type="radio"]:nth-of-type(8):checked ~ ul:nth-of-type(1) > li:nth-of-type(8),
    input[type="radio"]:nth-of-type(9):checked ~ ul:nth-of-type(1) > li:nth-of-type(9)
    cursor: auto
    color: hsl(from lightgreen h s 80%)
    background-color: hsl(from #2e2b2b h s 27%)
    border-bottom: solid 3px
    border-color: $primary

  ul:nth-of-type(1)
    display: block
    width: parent
    list-style: none
    margin: 0
    margin-bottom: 1em
    padding: 0
    border: solid
    border-width: 0 0 3px 0
    border-color: hsl(from #2e2b2b h s 12%)
    li
      margin-bottom: -3px
      background-color: hsl(from #2e2b2b h s 22%)
      display: inline-block
      //border: solid
      //border-bottom: solid 1px
      label
        display: inline-block
        padding: 0.6rem 2.3rem
        cursor: pointer
        margin: 0

  ul:nth-of-type(2) > li
    display: none

  > input[type="radio"]:nth-of-type(1):checked ~ ul:nth-of-type(2) > li:nth-of-type(1),
    input[type="radio"]:nth-of-type(2):checked ~ ul:nth-of-type(2) > li:nth-of-type(2),
    input[type="radio"]:nth-of-type(3):checked ~ ul:nth-of-type(2) > li:nth-of-type(3),
    input[type="radio"]:nth-of-type(4):checked ~ ul:nth-of-type(2) > li:nth-of-type(4),
    input[type="radio"]:nth-of-type(5):checked ~ ul:nth-of-type(2) > li:nth-of-type(5),
    input[type="radio"]:nth-of-type(6):checked ~ ul:nth-of-type(2) > li:nth-of-type(6),
    input[type="radio"]:nth-of-type(7):checked ~ ul:nth-of-type(2) > li:nth-of-type(7),
    input[type="radio"]:nth-of-type(8):checked ~ ul:nth-of-type(2) > li:nth-of-type(8),
    input[type="radio"]:nth-of-type(9):checked ~ ul:nth-of-type(2) > li:nth-of-type(9)
    display: block

