@import './variables'

$menu-col-size: 2

nav
  display: inline-block
  font-family: lato
  text-align: center
  background-color: $primary
  color: $fg-color
  ul
    padding-inline-start: 0
    list-style-type: none /* Remove bullets */
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(5em, 1fr))
    margin: 0
    font-size: 3em
    li
      display: block
    li:has(> a.active)
      box-sizing: border-box
      -moz-box-sizing: border-box
      -webkit-box-sizing: border-box
      border-bottom: 0.08em solid
  a
    line-height: 3
    font-size: 0.35em
    height: 100%
    width: 100%
    display: block
    vertical-align: middle
    color: inherit
    text-decoration: none
    //text-transform: uppercase

