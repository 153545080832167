@import './variables'

#root, body
  background-color: $bg-color
  color: $fg-color

main
  overflow-y: auto
  overflow-x: hidden
  padding: 2em
  a:not(.primary, .secondary)
    &:link
      color: lightskyblue
    &:visited 
      color: lightskyblue
    &:hover
      color: #fff
    &:active
      color: #ff4040
      text-decoration:none
      font-weight:normal

*.center_contents
  text-align: center

label
  margin-right: 0.4em
  font-weight: bold

form
  padding: 1rem 1.8rem
  //padding: 0

dialog
  font-family: lato
  color: $fg-color
  background-color: $bg-color
  border: 1px solid white
  outline: none
  min-width: 200px
  max-width: min(800px, 90lvw)
  min-height: 100px
  overflow: auto
  padding: 1rem 1.8rem
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

dialog::backdrop
  backdrop-filter: blur(10px)

ul[aria-label="Pagination"]
  list-style-type: none
  li
    display: inline-block
    margin: 0 .5em
    cursor: pointer
  li.disabled
    opacity: 70%
    cursor: no-drop

h1, h2, h3, h4
  font-family: lato

table
  border-collapse: collapse
  border-collapse: collapse
  margin: 25px 0
  font-family: open-sans
  min-width: 200px
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)
  thead tr
    background-color: $primary
    color: $fg-color
    text-align: left
  
  th, td
    padding: 12px 15px
  
  tbody
    tr
      border-bottom: 1px solid $fg-color 
    tr:nth-of-type(even)
      background-color:$bg-color-2 
    tr:last-of-type
      border-bottom: 2px solid $primary

$input-height: 1.5em
$input-width: 12em
$input-border-width: 1px

label:has(> input,select)
  display: block
  margin: 0.5em 0
  $input-padding-left: 0.25em
  padding-left: $input-padding-left

  input
    height: $input-height
    width: $input-width
  input[type='number'],input[type='text'],select
    display: block
    padding: 0
    color: $fg-color
    background-color: $bg-color
    border: solid $input-border-width
    border-radius: 0.5em
    font-size: inherit
    text-indent: 0.5em
    margin-left: $input-padding-left * -1

select
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  height: calc($input-height + (2 * $input-border-width))
  width: calc($input-width + (2 * $input-border-width))
  background: transparent
  background-image: url("data:image/svg+xml,<svg fill='white' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>")
  background-position: calc(100% - 0.75em) center
  background-size: calc($input-height - 0.5em)
  background-repeat: no-repeat

input[type="search"]
  border: 1px solid gray
  padding: 9px 4px 9px 40px
  border-radius: .8em
  color: $fg-color
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center

input[type="search"]::-webkit-search-cancel-button
  -webkit-appearance: none
  height: 1em
  width: 1em
  cursor: pointer
  border-radius: 50em
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%
  background-size: contain
  opacity: 0
  pointer-events: none
  filter: invert(1)

input[type="search"]:focus::-webkit-search-cancel-button 
  opacity: .3
  pointer-events: all

a.primary
  background-color: $primary
  border-radius: 8px
  border-style: none
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px
  box-sizing: border-box
  color: $fg-color
  cursor: pointer
  text-decoration: none
  font-weight: 600
  letter-spacing: normal
  outline: none
  padding: 4px 15px

a.button
    -webkit-appearance: button
    -moz-appearance: button
    appearance: button
    text-decoration: none

button,.button
  background-color: $primary
  &.accent
    background-color: $accent
  &.warn
    background-color: $warn
  border-radius: 8px
  border-style: none
  cursor: pointer
  font-weight: bold
  overflow: hidden
  padding-inline: .5em
  min-height: 2em
  vertical-align: top
  a
    font-size: 1em!important
    padding: 0!important
    line-height: initial!important

.non-primary
  background-color: $bg-color
  color: $primary

button:hover, .button:hover
  -webkit-filter: brightness(70%)
  opacity: 1
  transform: translateY(0)
  transition-duration: .35s
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px

.close
  position: absolute
  right: 20px
  top: 20px
  width: 30px
  height: 30px
  opacity: 0.3
.close:hover
  opacity: 1
.close:before, .close:after
  position: absolute
  left: 15px
  content: ' '
  height: 25px
  width: 2px
  background-color: $warn
.close:before
  transform: rotate(45deg)
.close:after
  transform: rotate(-45deg)

.button_group
  margin-top: 1em

svg
  width: 1.3em
  height: 1.3em
  fill: white
  display: flex
  align-items: center
  justify-content: center

img.round
  border-radius: 50%
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
