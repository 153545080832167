html
  color-scheme: dark light
  hanging-punctuation: first last

*, *::before, *::after
  box-sizing: border-box

*
  margin: 0
  padding: 0

body
  min-height: 100vh

main
  display: block

img, picture, svg, video
  display: block
  max-width: 100%

h1
  font-size: 2em
  margin: 0.50em 0

h1, h2, h3, h4, h5
  display: block
  text-wrap: balance

p
  max-width: 75ch
  text-wrap: pretty

@media (prefers-reduced-motion: no-preference)
  :has(:target)
    scroll-behavior: smooth
    scroll-padding-top: 3rem

a
  background-color: transparent

b,strong
  font-weight: bolder

code,kbd,samp
  font-family: monospace, monospace
  font-size: 1em

small
  font-size: 80%

sub,sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline
sub
  bottom: -0.25em
sup
  top: -0.5em

img
  border-style: none

button,input,optgroup,select,textarea
  font-family: inherit

button,select
  text-transform: none

button,[type="button"],[type="reset"],[type="submit"]
  -webkit-appearance: button

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner
  border-style: none
  padding: 0

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring
  outline: 1px dotted ButtonText

[type="search"]
  -webkit-appearance: textfield
  outline-offset: -2px

[type="search"]::-webkit-search-decoration
  -webkit-appearance: none

::-webkit-file-upload-button
  -webkit-appearance: button
  font: inherit

[hidden]
  display: none

