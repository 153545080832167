@import './variables.sass'

.spellHeading
  display: inline-block
  margin-right: 15px
  margin-top: 15px
  margin-bottom: 10px

.quickSection
  vertical-align: bottom
  display: inline-block
  margin-right: 40px
  div
    margin: 5px 0
    display: block
