.profile-nav
  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-rows: 1fr
  img
    padding: 1em
    display: inline-block
    grid-column: 1 / span 2
    grid-row: 1 / span 2
  .button_group
    grid-column-start: 2
    grid-row-start: 1

    *
      margin-block: 0.2em

